/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * FAQ card toggles
 ***********************************************/
(function ($) {

    $('.sp-faq-list .sp-faq-card').find('> .card-header').on('click', function() {
        var $card = $(this).closest('.sp-faq-card'),
            $list = $card.closest('.sp-faq-list');

        $list.find('.sp-faq-card').each(function () {
            if($(this).is($card)) { return; }
            $(this).find('> .card-contents').collapse('hide');
            $(this).removeClass('card-open');
        });

        $card.find('> .card-contents').collapse('show');
        $card.addClass('card-open');
    });

})(jQuery);